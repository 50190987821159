import * as React from "react"
import { graphql } from "gatsby"
import Page from "../templates/page"
export const query = graphql`
  query FrontpageQuery {
    sanityPage(slug: { current: { eq: "home" } }) {
      ...PageInfo
    }
  }
`
const IndexPage = ({ data }) => {
  return (
    <>
      <Page data={data} />
    </>
  )
}

export default IndexPage
